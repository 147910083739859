

<template>
  <div class="main-conent main-conent-minheight" v-loading="loading">
    <el-card shadow="never" class="border-none">
      <div slot="header">
        <el-row>
          <el-col :span="20">
            <el-select v-model="searchForm.type" clearable placeholder="请选择分类">
              <el-option v-for="item in categoties" :key="item.id" :label="item.title" :value="item.id"
                :disabled="item.disabled">
              </el-option>
            </el-select>
            <el-button type="primary" @click="dataBinding()">搜索</el-button>
            <el-button type="primary" @click="dataBinding()">刷新</el-button>
          </el-col>
          <el-col :span="4">
            <el-button type="primary" @click="handleEdit(0)">新增</el-button>
          </el-col>

        </el-row>
      </div>

      <el-table :data="tableData" stripe fit>
        <el-table-column prop="title" label="标题" width="250">
        </el-table-column>
        <el-table-column prop="categoryTitle" label="分类" width="100">
        </el-table-column>
        <el-table-column prop="author" label="作者">
        </el-table-column>
        <el-table-column prop="seeCount" label="浏览量">
        </el-table-column>
        <el-table-column prop="isPublish" label="是否上架">
          <template slot-scope="scope">
            <el-tag :type="scope.row.isPublish ? 'primary' : 'info'" disable-transitions>
              {{scope.row.isPublish?'已上架':'未上架'}}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="updateTime" label="更新时间">
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button v-if="!scope.row.isSuper" @click="handleEditModel(scope.row)" type="text">编辑</el-button>
            <el-button v-if="!scope.row.isSuper" @click="handleDeleteModel(scope.row)" type="text">删除</el-button>
          </template>
        </el-table-column>
      </el-table>

      <div class="margin-t16 text-right">

      </div>

    </el-card>
  </div>
</template>
<script>
import { getList, deleteModel } from '@/service/article_case.js';
import { getComboBox } from '@/service/article_category.js';


export default {
  data() {
    return {
      loading: false,
      searchOpen: false,
      totalCount: 0,
      searchForm: {
        page: 1,
        pageSize: 20,
        type: ''
      },
      tableData: [],
      categoties: []
    };
  },
  created() {
    this.comboBoxBinding();
    this.dataBinding();
  },
  methods: {
    //跳转编辑案例页
    handleEditModel(row) {
      this.$router.push({ name: 'case-edit', query: { articleId: row.id } });
    },
    //加载页面数据
    dataBinding() {
      this.loading = true;
      getList(this.searchForm).then(res => {
        this.totalCount = res.total;
        this.tableData = res.list;
        this.loading = false;
      });
    },
    //获取下拉数据
    comboBoxBinding() {
      getComboBox({ type: 1 }).then(cob => {
        this.categoties = cob;
      });
    },
    //跳转新增案例页
    handleEdit() {
      this.$router.push({ name: 'case-edit' });
    },
    //删除案例
    handleDeleteModel(row) {
      this.loading = true;
      deleteModel(row.id).then(res => {
        if (res === true) {
          this.dataBinding();
        }
        else {
          this.$message("删除操作失败");
        }
        this.loading = false;
      });
    }
  }
};
</script>